.tariffsWrapper {
    padding: 0 62px 96px;
    gap: 60px;
}

.titleWrapper {
    width: 324px;
    padding-top: 192px;

    h2 {
        font-size: 34px;
        font-weight: 700;
        line-height: 40px;
        letter-spacing: -0.01em;
        text-align: left;
        text-transform: uppercase;
        color: var(--ds-main-black);
    }

    p {
        font-size: 16px;
        font-weight: 350;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
        color: var(--ds-main-dark-gray);
    }
}

.contentWrapper {
    padding-top: 128px;
    gap: 24px;

    & > div:first-child {
        justify-content: flex-end;
    }

    .tariffsCardWrapper {
        gap: 12px;
    }
}

@media (max-width: 1359px) {
    .tariffsWrapper {
        padding: 54px 48px 72px;
        flex-direction: column;
        gap: 31px;
    }

    .titleWrapper {
        width: 301px;
        padding-top: 0;

        h2 {
            font-size: 28px;
            line-height: 32px;
            letter-spacing: 0;
        }

        p {
            margin-top: 11px;
            font-size: 15px;
            line-height: 20px;
        }
    }

    .contentWrapper {
        padding-top: 0;

        & > div:first-child {
            justify-content: flex-start;
        }
    }
}

@media (max-width: 1023px) {
    .tariffsWrapper {
        padding: 38px 24px 64px;
        gap: 35px;
    }

    .titleWrapper {
        width: 336px;

        p {
            margin-top: 7px;
        }
    }

    .tariffsCardWrapper {
        flex-direction: column;
    }

    .contentWrapper {
        gap: 32px;
    }
}

@media (max-width: 767px) {
    .tariffsWrapper {
        padding: 33px 18px 64px;
        gap: 27px;
    }

    .titleWrapper {
        width: 100%;

        h2 {
            font-size: 22px;
            line-height: 24px;
            letter-spacing: -0.01em;
        }

        p {
            margin-top: 17px;
            font-size: 14px;
        }
    }
}
