.productWrapper {
  padding: 34px 62px 50px;
}

.productListWrapper {
  display: grid;
  gap: 120px;
  margin-top: 80px;
}

@media (max-width: 1359px) {
  .productWrapper {
    padding: 65px 24px 48px;
  }

  .productListWrapper {
    gap: 80px;
    margin-top: 65px;
  }
}

@media (max-width: 1023px) {
  .productWrapper {
    padding: 47px 24px 40px;
  }

  .productListWrapper {
    gap: 69px;
  }
}

@media (max-width: 767px) {
  .productWrapper {
    padding: 80px 18px 80px;
  }

  .productListWrapper {
    gap: 70px;
    margin-top: 24px;
  }
}
