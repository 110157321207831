.wrapper {
  margin: -44px 32px 12px auto;
}

.actionButton {
  width: 36px;
  height: 36px;
  margin-right: 3px;
  padding: 0;
  background-color: rgba(#d7e6f5, 0.2);
  border: 1px solid var(--ds-border-gray);
}

.filterButton {
  max-height: 36px;
  margin-left: 16px;
  background-color: var(--ds-main-blue);
  padding: 6px 19px;

  & > span {
    color: var(--ds-main-white);
  }

  & > svg {
    margin-right: 8px;
  }
}

.dialogContainer {
  & > div:first-child {
    & > div:last-child {
      position: absolute;
      top: 8px;
      right: 8px;
    }
  }
}

.dialog {
  width: 514px;
}

.dialogWrapper {
  margin: 48px;
}

.dialogHeader {
  font-size: 24px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
}

.dialogContent {
  margin: 24px 0 30px;

  font-family:
    Favorit Pro,
    sans-serif;
  font-size: 16px;
  font-weight: 350;
  line-height: 24px;
  letter-spacing: 0;
}

.dialogInput {
  width: 100%;
  padding: 14px 24px;
  margin: 38px 0 26px;

  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
}

.dialogActionButton {
  width: 100%;
  display: block;
  background-color: var(--ds-main-gray);
  padding: 13px;

  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
}

.dialogActionButtonSuccess {
  margin-left: 24px;
  margin-right: 0;
  background-color: var(--ds-main-blue);
}
