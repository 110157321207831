.closeIcon {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.dialogMobile {
  width: 100vw !important;
}

.modal {
  border-radius: 6px;
  border: 1px solid #d8dfe6;

  & > * {
    border-radius: 6px;
  }
}
