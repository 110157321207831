.priceFooterWrapper {
    gap: 28px;

    button {
        width: 100%;
        height: 56px;
        padding: 16px 24px;
        border: none;
        border-radius: 6px;
        background: var(--ds-main-red);
        gap: 8px;

        span {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0;
            text-align: center;
            color: var(--ds-main-white);
            display: contents;
        }

        &.active {
            background: var(--ds-bg-green);
        }
    }
}

.priceWrapper {
    gap: 4px;
    font-family: Inter, sans-serif;
    font-size: 22px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0;
    text-align: right;
    color: var(--ds-main-black);

    span {
        font-family:
            Favorit Pro,
            sans-serif;
        font-size: 18px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0;
        text-align: right;
    }
}

.discountWrapper {
    height: 24px;
    padding: 2px 8px 3px;
    border: 1px solid var(--ds-main-red);
    border-radius: 4px;
    gap: 8px;

    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0;
    text-align: right;
    text-transform: uppercase;
    color: var(--ds-main-red);

    span {
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0;
        text-align: right;
        color: inherit;
    }
}

@media (max-width: 767px) {
    .priceFooterWrapper {
        button {
            height: 48px;
            padding: 12px 24px;
        }
    }
}
