.apiDescContainer {
    min-height: 100vh;
    padding: 48px 36px;
    display: flex;
    align-items: start;
    gap: 40px;

    .tableOfContents {
        width: 337px;
        min-height: 100px;
        border-radius: 12px;
        border: 1px solid var(--ds-main-light-gray);
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 30px;

        .header {
            & > div {
                background-color: var(--ds-main-white);
                border: none;
            }
        }

        .headerAction {
            background-color: var(--ds-main-white);
            border: none;
            padding: 10px 2px;
            display: flex;
            flex-direction: row-reverse;
            justify-content: start;
            align-items: center;
            gap: 12px;

            & > svg {
                width: 15px;
                height: 15px
            }

            & > span {
                font-size: 16px;
                font-style: normal;
                font-weight: 350;
                line-height: 20px;
                color: var(--ds-main-black);
            }
        }

        .toggleableContent {
            border: none;
            padding: 0 0 4px 16px;


            & > div {
                border: none;
                & > ul {
                    & > li {
                        padding: 7px 0;
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 350;
                        line-height: 18px;
                        color: var(--ds-main-dark-gray);
                    }
                }
            }
        }
    }

    .content {
        max-width: 861px;
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 48px;

        .contentH1 {
            font-size: 40px;
            font-style: normal;
            font-weight: 400;
            line-height: 48px;
            color: var(--ds-main-black);
        }

        .contentH2 {
            font-size: 30px;
            font-style: normal;
            font-weight: 400;
            line-height: 36px;
            color: var(--ds-main-black);
        }

        .contentH3 {
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px;
            color: var(--ds-main-black);
        }

        .contentH5 {
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
            color: var(--ds-main-black);
        }

        & > div {
            & > p {
                font-size: 16px;
                font-style: normal;
                font-weight: 300;
                line-height: 18px;
                color: var(--ds-main-dark-gray);
            }
        }

        .listWithHeader {
            display: flex;
            flex-direction: column;
            align-items: start;
            gap: 24px;

            & > ul {
                padding: 0 20px;
                font-size: 16px;
                font-style: normal;
                font-weight: 350;
                line-height: 35px;
                color: var(--ds-main-dark-gray);

                & > li::marker {
                    color: var(--ds-main-red);
                }
            }
        }
    }

    .blockDivider {
        width: 100%;
        border-bottom: 1px solid var(--ds-border-gray);
    }
}

@media (max-width: 1024px) {
    .apiDescContainer {
        flex-direction: column;

        .tableOfContents {
            width: 100%

        }

        .content {
            max-width: 726px;
        }
    }
}

@media (max-width: 767px) {
    .apiDescContainer {
        .content {
            max-width: 719px;
        }
    }
}

@media (max-width: 360px) {
    .apiDescContainer {
        .content {
            max-width: 324px;
            gap: 40px;
        }
    }
}


