.headerWrapper {
  position: relative;
  height: 360px;
  width: 100%;
  background:
    url("../../../../shared/images/png/productsHeaderSquares.png") right / auto 100% no-repeat,
    url("../../../../shared/images/png/productsHeader.png") center / 100% 100% no-repeat;
  padding: 155px 0 0 62px;

  h1 {
    font-size: 80px;
    font-weight: 700;
    line-height: 64px;
    letter-spacing: -0.8px;
    text-align: left;
  }
}

@media (max-width: 1359px) {
  .headerWrapper {
    height: 240px;
    padding: 105px 0 0 24px;

    h1 {
      font-size: 42px;
      line-height: 42px;
    }
  }
}

@media (max-width: 767px) {
  .headerWrapper {
    padding: 97px 0 0 18px;
    height: 216px;

    h1 {
      font-size: 30px;
      line-height: 42px;
      letter-spacing: -0.7px;
    }
  }
}
