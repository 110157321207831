.modebar {
    display: flex;
    gap: 12px;
    border-radius: 6px;
    border: 1px solid var(--ds-border-gray);
    background: var(--ds-bg-extra-light-gray);
    padding: 6px;
    position: absolute;
    left: 0;
    top: 0;

    & > svg {
        cursor: pointer;
    }
}
