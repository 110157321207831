.mainDataWrapper {
    gap: 32px;
    width: 100%;

    img {
        width: 60px;
        height: 60px;
    }

    & > div {
        gap: 12px;
        padding: 0 124px 0 0;
        width: 100%;

        & > p {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            text-align: left;
        }
    }
}

.tableDataWrapper {
    gap: 24px;
    padding: 12px 0 0 0;
    min-width: 726px;

    .dataUpdateWrapper {
        gap: 8px;
        width: calc(100% - 74.5px);
        padding-left: 74.5px;

        span {
            font-family:
                Favorit Pro,
                sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            text-align: center;
            color: var(--ds-main-dark-gray);
        }
    }

    .historicalDataWrapper {
        font-family:
            Favorit Pro,
            sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        width: 100%;
        justify-content: center;
    }

    .pythonLibWrapper {
        width: 100%;
    }
}

.borderBtm {
    padding-bottom: 31px !important;
    border-bottom: 1px solid var(--ds-main-gray);
}

@media (max-width: 1400px) and (min-width: 1360px) {
    .mainDataWrapper {
        & > div {
            max-width: 360px;
        }
    }
}

@media (max-width: 1359px) {
    .mainDataWrapper {
        gap: 24px;
        min-width: 370px;

        & > div {
            gap: 12px;
            padding: 0;
        }
    }

    .tableDataWrapper {
        min-width: 408px;
        width: auto;
        justify-content: space-between;

        .dataUpdateWrapper {
            padding-left: 0;
            width: 100%;
        }

        .pythonLibWrapper {
            min-width: 114px;
        }
    }
}

@media (max-width: 1023px) {
    .mainDataWrapper {
        gap: 24px;
        min-width: 370px;

        & > div {
            gap: 12px;
            padding: 0;
        }
    }

    .tableDataWrapper {
        padding: 56px 0 0 0;
        justify-content: end;
        align-items: center;
        min-width: auto;
        gap: 50px;

        & > div:first-child {
            min-width: 119px;
        }

        .dataUpdateWrapper {
            padding-left: 0;
            width: 100%;
            justify-content: end;
        }

        .historicalDataWrapper {
            text-align: right;
            justify-content: flex-end;
        }

        .pythonLibWrapper {
            width: 30px;
            min-width: 30px;
            margin-right: 27px;
        }
    }
}
