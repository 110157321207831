.headerWrapper {
  width: 100%;
}

.headerLinksBlock {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .navigationList {
    display: flex;
    align-items: center;
    gap: 36px;
    list-style-type: none;

    .navigationItem {

      text-transform: uppercase;
      font-family: Favorit Pro, sans-serif;
      font-size: 13px;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 2%;

    .navigationLink {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 4px;
      color: var(--ds-dtsip-text-black-100);
      transition: transform 0.3s linear;
    };

      .isDisabledLink {
        opacity: 30%;
        cursor: not-allowed;
      }
    }
  }
}

