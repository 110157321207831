.blockDivider {
    width: 100%;
    border-bottom: 1px solid var(--ds-border-gray);
}

.blockTitle {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 12px;

    & > i {
        color: var(--ds-main-gray);
    }

    & > p {
        color: var(--ds-main-black);
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: -1%;
        text-transform: uppercase;
    }
}

.blockParamSearch {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 8px;

    .blockParamSearchInput {
        width: 190px;
        height: 36px;
        border-radius: 6px;
        border: 1px solid var(--ds-border-gray);

        & > div {
            color: var(--ds-main-gray);
            padding: 8px 18px;
            font-size: 14px;
            font-style: normal;
            font-weight: 350;
            line-height: 20px;
        }

        & > span {
            color: var(--ds-main-gray);
            padding: 8px 18px;
            font-size: 14px;
            font-style: normal;
            font-weight: 350;
            line-height: 20px;
        }
    }

    .profitParam {
        padding-top: 8px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 8px;

        & > div {
            & > i {
                font-size: 12px;
            }

            & > input {
                padding: 8px;
                width: 118px;
                height: 36px;
                border-radius: 6px;
                border: 1px solid var(--ds-border-gray);
            }
        }
    }
}

.inputLabel {
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 16px;
}

.dropdownInput {
    height: 36px;
    border-radius: 6px;
    border: 1px solid var(--ds-border-gray);

    & > span {
        color: var(--ds-main-gray);
        padding: 8px 18px;
        font-size: 14px;
        font-style: normal;
        font-weight: 350;
        line-height: 20px;
    }
}

.calendarInput {
    height: 36px;
    border-radius: 6px;
    border: 1px solid var(--ds-border-gray);

    & > input {
        min-width: 4.5rem;
        padding: 6px 12px 6px 18px;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 16px;
        border: none;
    }

    & > button {
        border: none;
        background-color: var(--ds-main-white);
        color: var(--ds-main-blue);
    }
}

.listDownloadButton {
    width: 185px;
    background-color: var(--ds-main-white);
    color: var(--ds-main-black);
    padding: 6px 20px 6px 16px;
    height: 36px;
    border-radius: 6px;
    border: 1px solid var(--ds-border-gray);

    & > span:first-child {
        padding: 0 5px;
        color: var(--ds-main-blue);
    }

    & > span:last-child {
        padding: 0 5px;
        font-size: 13px;
        font-style: normal;
        font-weight: 350;
        line-height: 20px;
        color: var(--ds-main-black);
    }
}

.extendedSearchLabel {
    padding-top: 16px;
    align-self: start;
    font-size: 14px;
    font-weight: 350;
    line-height: 18px;
    color: var(--ds-main-blue);
    text-decoration: underline;
    cursor: pointer;
}

.autoCompleteInput {
    width: 443px;
    height: 36px;
    border-radius: 6px;
    border: 1px solid var(--ds-border-gray);

    & > input {
        padding: 6px 12px 6px 18px;
        font-size: 14px;
        font-style: normal;
        font-weight: 350;
        line-height: 20px;
        border: none;
    }

    & > button {
        border: none;
        background-color: var(--ds-main-white);
        color: var(--ds-main-light-gray);
    }
}

.profitInput {
    width: 118px;
    height: 36px;
    border-radius: 6px;
    border: 1px solid var(--ds-border-gray);
    color: var(--ds-main-gray);
    padding: 8px 18px;
    font-size: 14px;
    font-style: normal;
    font-weight: 350;
    line-height: 20px;
}
