.linkSection {
    background: linear-gradient(277deg, rgba(216, 225, 235, 0.8) 0%, rgba(216, 225, 235, 0.2) 100%);
    padding: 80px 62px 80px 62px;

    & > h2 {
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%; /* 40px */
        text-transform: uppercase;
        margin-right: 194px;
    }

    & > div {
        gap: 48px;
    }
}

@media (max-width: 1359px) {
    .linkSection {
        padding: 80px 24px 80px 24px;
        flex-direction: column;

        & > h2 {
            margin-right: 0;
            margin-bottom: 56px;
            font-size: 32px;
        }

        & > div {
            gap: 22px;
        }
    }
}

@media (max-width: 1023px) {
    .linkSection {
        & > div {
            flex-direction: column;
            width: 100%;
        }
    }
}

@media (max-width: 767px) {
    .linkSection {
        padding: 60px 18px;

        & > h2 {
            font-size: 20px;
            margin-bottom: 40px;
        }
    }
}
