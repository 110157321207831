.dataItemWrapper {
    gap: 24px;
    cursor: pointer;
}

.titleWrapper {
    gap: 12px;

    & > h4 {
        font-size: 24px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;
    }

    .tagsWrapper {
        gap: 6px;

        span {
            height: 20px;
            padding: 5px 8px 5px 8px;
            border-radius: 4px;
            background: var(--ds-main-light-gray);

            font-family:
                Favorit Pro,
                sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 10px;
            text-align: left;
            color: var(--ds-main-dark-gray);
        }
    }
}

@media (max-width: 767px) {
    .dataItemWrapper {
        gap: 12px;
    }

    .titleWrapper {
        gap: 8px;

        & > h4 {
            font-size: 18px;
        }

        .tagsWrapper {
            span {
                height: 18px;
                font-size: 12px;
                line-height: 8px;
            }
        }
    }
}
