.mainLogoContainer {
    background-image: url('../../../../../shared/images/algopack/AlgopackMainBack1920.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
    padding: 95px 62px 80px 62px;
    width: 100%;
    height: 500px;

    & > p {
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: 24px;
        width: 600px;

        & {
            margin-bottom: 1em;
        }
        & + p {
            margin-bottom: 48px;
        }
    }

    .getAccessButton {
        padding: 16px 30px 16px 20px;
        gap: 8px;
        background-color: var(--ds-bg-green);
        border-radius: 6px;
        border: none;
        height: 56px;

        & > span {
            display: block;
            font-size: 16px;
            font-style: normal;
            font-weight: 350;
            line-height: 24px;
            color: var(--ds-main-white);
        }
    }
}

.header {
    width: 654px;
    height: 72px;
    margin-bottom: 32px;

    & > svg {
        width: 654px;
        height: 72px;
        margin-right: 40px;
    }
}

.noticeModal {
    button {
        border: none;
        background-color: var(--ds-main-black);

        & > span {
            color: var(--ds-main-white);
        }
    }
}

@media (max-width: 1359px) {
    .mainLogoContainer {
        background-image: url('../../../../../shared/images/algopack/AlgopackMainBack1024.png');
        padding: 65px 48px 60px 48px;
        height: 400px;

        & > p {
            font-size: 16px;
            line-height: 20px;
            width: 515px;
            & + p {
                margin-bottom: 32px;
            }
        }
    }

    .header {
        height: 48px;
        margin-bottom: 30px;
        & > svg {
            width: 438px;
            height: 48px;
            margin-right: 26px;
        }
    }
}

@media (max-width: 1023px) {
    .mainLogoContainer {
        padding: 78px 48px 60px 48px;

        .header {
            height: 36px;
            & > svg {
                width: 328.5px;
                height: 36px;
            }
        }
    }
}

@media (max-width: 767px) {
    .mainLogoContainer {
        background-image: url('../../../../../shared/images/algopack/AlgopackMainBack360.png');
        padding: 30px 18px;
        height: 571px;

        & > p {
            font-size: 15px;
            width: 324px;
            & + p {
                margin-bottom: 30px;
            }
        }

        .header {
            height: 30px;
            max-width: 100%;
            margin-bottom: 22px;
            & > svg {
                width: 274px;
                height: 30px;
                margin-right: 17px;
            }
        }

        .getAccessButton {
            padding: 12px 20px 12px 14px;
            height: 48px;
            gap: 6px;
            & > svg {
                font-size: 14px;
                width: 20.03px;
                height: 19.13px;
            }
            & > span {
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0;
            }
        }
    }
}
