.dynamicTitleWrapper {
    margin: 130px 62px 0;

    & > p {
        font-size: 18px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        margin-bottom: 20px;
    }

    & > h2 {
        width: 1067px;
        font-size: 56px;
        font-weight: 500;
        line-height: 60px;
        text-align: left;
        text-transform: uppercase;

        span {
            color: var(--ds-main-red);
        }
    }
}

@media (max-width: 1359px) {
    .dynamicTitleWrapper {
        margin: 95px 48px 0;

        & > h2 {
            width: 100%;
            font-size: 46px;
            line-height: 50px;
        }
    }
}

@media (max-width: 1023px) {
    .dynamicTitleWrapper {
        margin: 80px 24px 0;

        & > h2 {
            font-size: 36px;
            line-height: 40px;
        }
    }
}

@media (max-width: 767px) {
    .dynamicTitleWrapper {
        margin: 48px 18px 0;

        & > p {
            width: 190px;
            font-size: 14px;
            line-height: 16px;
            margin-bottom: 10px;
        }

        & > h2 {
            font-size: 17px;
            line-height: 21px;
            letter-spacing: -0.01em;
        }
    }
}
