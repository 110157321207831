.interactiveContainer {
    width: 100%;
    padding: 44px 36px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    gap: 2rem;
}

@media (max-width: 1024px) {
    .interactiveContainer{

    }
}

@media (max-width: 767px) {
    .interactiveContainer{
        padding: 30px 24px;
        flex-direction: column;
        justify-content: start;
    }
}

@media (max-width: 360px) {
    .interactiveContainer{
        padding: 20px 18px;
    }
}


