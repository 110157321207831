.logoBlockWrapper {
    height: 500px;
    background:
        url('../../../../../shared/images/svg/landingHeader/cubesXl.svg') center left / auto 100% no-repeat,
        url('../../../../../shared/images/propack/png/propackHeaderBg.png') center right / auto 100% no-repeat;
}

.contentWrapper {
    padding: 90px 62px 54px 62px;

    & > svg {
        height: 60px;
        width: 560px;
        margin-bottom: 32px;
    }

    & > p {
        width: 590px;
        font-weight: 350;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 12px;
    }

    & > ul {
        margin-bottom: 32px;
        & > li {
            font-weight: 350;
            font-size: 16px;
            line-height: 24px;
            list-style: none;
            position: relative;
            padding-left: 18px;
            margin-left: 8px;
            margin-bottom: 10px;
        }
        & > li:last-child {
            margin-bottom: 0;
        }
        & > li:before {
            content: '';
            position: absolute;
            top: 8px;
            left: 0;
            display: inline-block;
            width: 5px;
            height: 5px;
            background-color: var(--ds-main-red);
        }
    }

    & > div {
        gap: 12px;

        & > button {
            height: 56px;
            padding: 18px 30px 18px 20px;
            display: flex;
            gap: 8px;
            align-items: center;

            & > span {
                font-weight: 350;
                font-size: 16px;
                line-height: 20px;
                display: block;
            }

            &:first-child {
                background-color: var(--ds-main-red);
                border: none;

                & > span {
                    color: var(--ds-main-white);
                }
            }

            &:last-child {
                background-color: var(--ds-main-white);
                border: 1px solid var(--ds-main-gray);

                & > span {
                    color: var(--ds-main-black);
                }
            }
        }
    }
}

@media (max-width: 1359px) {
    .logoBlockWrapper {
        height: 400px;
        background:
            url('../../../../../shared/images/svg/landingHeader/cubesLg.svg') center left / auto 100% no-repeat,
            url('../../../../../shared/images/propack/png/propackHeaderBg.png') center right / auto 100% no-repeat;
    }

    .contentWrapper {
        padding: 74px 48px 45px 48px;

        & > svg {
            height: 48px;
            width: 450px;
            margin-bottom: 20px;
        }

        & > p {
            width: 560px;
            font-size: 16px;
            line-height: 20px;
        }

        & > ul {
            margin-bottom: 28px;
            & > li {
                font-size: 15px;
                line-height: 20px;
                margin-bottom: 8px;
            }
        }
    }
}

@media (max-width: 1023px) {
    .logoBlockWrapper {
        background:
            url('../../../../../shared/images/svg/landingHeader/cubesMd.svg') center left / auto 100% no-repeat,
            url('../../../../../shared/images/propack/png/propackHeaderBg.png') center right / auto 100% no-repeat;
    }

    .contentWrapper {
        padding: 70px 24px 40px 24px;

        & > svg {
            height: 40px;
            width: 380px;
        }

        & > p {
            width: 480px;
            margin-bottom: 8px;
        }

        & > ul {
            margin-bottom: 28px;
            & > li {
                margin-bottom: 8px;
            }
        }
    }
}

@media (max-width: 767px) {
    .logoBlockWrapper {
        height: 725px;
        background:
            url('../../../../../shared/images/svg/landingHeader/cubesSm.svg') left bottom / 144px 288px no-repeat,
            url('../../../../../shared/images/propack/png/propackHeaderBgSm.png') right bottom / 100% 288px no-repeat;
    }

    .contentWrapper {
        padding: 35px 18px 24px 18px;
        height: 437px;
        background: var(--ds-main-white);

        & > svg {
            height: 28px;
            width: 261px;
            margin-bottom: 16px;
        }

        & > p {
            width: 100%;
            font-size: 15px;
            line-height: 20px;
            margin-bottom: 8px;
        }

        & > ul {
            margin-bottom: 28px;
            & > li {
                font-size: 14px;
                margin-bottom: 6px;
            }
        }

        & > div {
            flex-direction: column;
            gap: 6px;
            & > button {
                width: 100%;
                height: 48px;
                display: flex;
                justify-content: center;

                & > span {
                    font-size: 14px;
                    line-height: 24px;
                    display: block;
                }
            }
        }
    }
}
