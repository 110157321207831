.pagButton {
  border-radius: 6px;
  border: 1px solid var(--ds-main-gray);
  width: 56px;
  height: 56px;
  padding: 16px 14px;
  margin-right: 12px;
  background-color: var(--ds-main-white);

  & > span {
    color: var(--ds-main-black);
    font-size: 16px;
    font-style: normal;
    font-weight: 350;
    line-height: 24px;
  }

  &.current {
    background: var(--ds-main-black);
    color: var(--ds-main-white);

    & > span {
      color: var(--ds-main-white);
    }
  }
}

.threeDotsP {
  margin-right: 12px;
  text-align: center;
}

.textButton {
  min-width: 120px;
  padding: 16px 24px;
  border-radius: 6px;
  background: var(--ds-main-red);
  border: none;

  & > span {
    color: var(--ds-main-white);
    font-size: 16px;
    font-weight: 350;
    line-height: 24px;
  }
}

.prevButton {
  margin-right: 12px;
}
