.tariffCardWrapper {
    width: 100%;
    border: 1px solid var(--ds-border-gray);
    border-top: 5px solid var(--ds-main-black);
    border-radius: 6px;
}

.titleWrapper {
    margin: 0 36px;
    padding: 48px 0 28px;
    border-bottom: 1px solid var(--ds-border-gray);

    span {
        font-size: 12px;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: 0;
        text-align: left;
        text-transform: uppercase;
        color: var(--ds-main-gray);
    }

    h4 {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0;
        text-align: left;
        text-transform: uppercase;
        color: var(--ds-main-black);
    }
}

.contentWrapper {
    margin: 0 36px;
    padding: 29px 0 58px;

    p {
        font-size: 16px;
        font-weight: 350;
        line-height: 20px;
        letter-spacing: 0;
        text-align: left;
        margin-bottom: 18px;
    }

    ul {
        list-style-type: none;

        li {
            padding: 2px 0;
            gap: 8px;
            font-size: 16px;
            font-weight: 350;
            line-height: 20px;
            letter-spacing: 0;
            text-align: left;
            color: var(--ds-main-black);

            &::before {
                content: url('../../../../../shared/images/svg/checkMarkRed.svg');
                display: block;
            }
        }
    }
}

.footerWrapper {
    padding: 28px 36px 32px;
    background: var(--ds-bg-extra-light-gray);
}

.activePeriodWrapper {
    position: relative;
    bottom: 0;
    height: 50px;
    background: var(--ds-main-light-gray);
    color: var(--ds-main-gray);
    font-size: 14px;
    font-weight: 350;
    line-height: 18px;
    letter-spacing: 0;
}

@media (max-width: 767px) {
    .titleWrapper {
        margin: 0 24px;
        padding: 36px 0 20px;
    }

    .contentWrapper {
        margin: 0 24px;
        padding: 23px 0 49px;

        ul {
            li {
                font-size: 15px;
            }
        }
    }

    .footerWrapper {
        padding: 28px 24px 24px;
    }
}
