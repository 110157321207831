.headerWrapper {
    width: 100%;
    height: 500px;
    background:
        url('../../../shared/images/svg/landingHeader/cubesXl.svg') center left / auto 100% no-repeat,
        url('../../../shared/images/priceCenter/png/PriceCenterNew.png') center right / auto 100% no-repeat;
}

.contentWrapper {
    padding: 122px 0 0 62px;

    & > svg {
        margin-top: 36px;
    }

    p {
        width: 590px;
        margin-top: 22px;
        font-size: 18px;
        font-weight: 350;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
    }
}

.buttonWrapper {
    margin-top: 34px;
    gap: 12px;

    button {
        display: flex;
        height: 56px;
        padding: 16px 30px;
        border-radius: 6px;
        gap: 8px;

        span {
            font-size: 16px;
            font-weight: 350;
            line-height: 20px;
            letter-spacing: 0;
            text-align: left;
            color: inherit;
            margin-top: 4px;
        }
    }

    .redBtn {
        color: var(--ds-main-white);
        background: var(--ds-main-red);
        border: none;
    }

    .whiteBtn {
        color: var(--ds-main-black);
        background: var(--ds-main-white);
        border: 1px solid var(--ds-border-gray);
    }
}

@media (max-width: 1359px) {
    .headerWrapper {
        height: 400px;
        background:
            url('../../../shared/images/svg/landingHeader/cubesLg.svg') center left / auto 100% no-repeat,
            url('../../../shared/images/priceCenter/png/PriceCenterNew.png') center right / auto 100% no-repeat;
    }

    .contentWrapper {
        padding: 89px 0 0 48px;

        & > svg {
            margin-top: 30px;
            width: 449px;
            height: 48px;
        }

        p {
            width: 464px;
            margin-top: 20px;
            font-size: 16px;
            line-height: 20px;
        }
    }

    .buttonWrapper {
        margin-top: 28px;
    }
}

@media (max-width: 1023px) {
    .headerWrapper {
        background:
            url('../../../shared/images/svg/landingHeader/cubesMd.svg') center left / auto 100% no-repeat,
            url('../../../shared/images/priceCenter/png/PriceCenterNew.png') center right / auto 100% no-repeat;
    }

    .contentWrapper {
        padding: 93px 0 0 24px;

        & > svg {
            width: 374px;
            height: 40px;
        }

        p {
            width: 360px;
        }
    }
}

@media (max-width: 767px) {
    .headerWrapper {
        height: 562px;
        background:
            url('../../../shared/images/svg/landingHeader/cubesSm.svg') left bottom / 144px 288px no-repeat,
            url('../../../shared/images/priceCenter/png/PriceCenterNewSm.png') right bottom / 100% 288px no-repeat;
    }

    .contentWrapper {
        padding: 36px 18px 24px;

        & > svg {
            width: 262px;
            height: 28px;
            margin-top: 0;
        }

        p {
            width: 100%;
            font-size: 15px;
            margin-top: 17px;
        }
    }

    .buttonWrapper {
        margin-top: 27px;
        flex-direction: column;

        button {
            justify-content: center;
            height: 48px;
            padding: 12px 20px 12px 12px;
            gap: 8px;

            span {
                font-size: 14px;
                line-height: 24px;
                display: contents;
            }
        }
    }
}
