.section {
    padding: 76px 62px 68px 62px;
}

.productsContainer {
    width: 980px;
}

.productsWrapper {
    width: 980px;
    gap: 24px;
    overflow: hidden;
}

.cardWrapper {
    height: 48px;
    width: calc(100% - 48px);

    & > div {
        & > svg {
            margin-left: auto;
            cursor: pointer;
        }

        & > span {
            display: none;
        }
    }

    .noCart {
        gap: 18px;

        svg {
            margin: 0;
            position: static;
        }

        p {
            font-size: 14px;
            font-weight: 350;
            line-height: 18px;
            text-align: left;
            color: var(--ds-main-gray);
        }
    }
}

.safetyWrapper {
    width: 100%;
    padding-right: 50px;
}

.accountCardWrapper {
    margin-right: 45px;
    margin-top: -142px;
}

.safetyOuterWrapper {
    margin-top: -8px;
}

.goToProductWrapper {
    width: 100%;
    height: 280px;
    background: var(--ds-bg-pale-blue);
    border: 1px solid var(--ds-border-gray);
    border-radius: 6px;
    padding: 36px 30px;

    & > svg {
        width: 36px;
        height: 36px;
        margin-bottom: 29px;
    }

    & > p {
        width: 520px;
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
        text-align: center;
        color: var(--ds-main-gray);
        margin-bottom: 48px;
    }

    & > button {
        height: 42px;
        padding: 12px 24px;
        border: none;
        border-radius: 6px;
        background: var(--ds-main-red);

        span {
            font-size: 16px;
            font-weight: 350;
            line-height: 18px;
            text-align: left;
            color: var(--ds-main-white);
        }
    }
}

.modalWrapper {
    width: 480px;

    & > div {
        padding: 48px;

        & > div {
            & > h2 {
                font-family: 'Favorit Pro', sans-serif;
                font-size: 24px;
                font-weight: 700;
                line-height: 24px;
                text-align: left;
                text-transform: uppercase;
                color: var(--ds-main-black);
                margin-bottom: 19px;
            }

            & > p {
                font-family: 'Favorit Pro', sans-serif;
                font-size: 16px;
                font-weight: 300;
                line-height: 20px;
                text-align: left;
                color: var(--ds-main-black);
                margin-bottom: 32px;
            }

            & > button {
                width: 127px;
                height: 42px;
                padding: 12px 24px 12px 24px;
                gap: 12px;
                border: none;
                border-radius: 6px;
                background: var(--ds-main-black);

                span {
                    font-family: 'Favorit Pro', sans-serif;
                    font-size: 16px;
                    font-weight: 350;
                    line-height: 18px;
                    text-align: left;
                    color: var(--ds-main-white);
                }
            }
        }
    }
}

@media screen and (max-width: 1929px) {
    .goToProductWrapper {
        padding: 36px 71px;
    }
}

@media (max-width: 1599px) {
    .section {
        padding: 0 24px;
    }
    .cardWrapper {
        width: 100%;
    }
    .safetyWrapper {
        padding-right: 0;
    }

    .productsContainer {
        width: 100%;
    }

    .productsWrapper {
        width: 100%;
        gap: 24px;
    }
    .accountCardWrapper {
        margin-top: -67px;
        margin-bottom: 60px;
    }
}

@media (max-width: 1023px) {
    .productsWrapper {
        width: 100%;
        gap: 24px;
    }
    .accountCardWrapper {
        margin-right: 0;
        margin-bottom: 52px;
    }

    .cardWrapper {
        height: auto;
        flex-wrap: wrap;
        gap: 18px 0;
        & > div {
            & > svg {
                position: absolute;
                top: 0;
                right: 0;
            }
        }
    }
}

@media (max-width: 767px) {
    .section {
        padding: 0;
    }

    .section > * {
        padding: 0 18px;
    }
    .accountCardWrapper {
        margin-top: -55px;
        margin-bottom: 40px;
    }

    .cardWrapper {
        height: auto;
        flex-direction: column;
        gap: 16px 0;
        width: 100%;

        & > div {
            margin: 0;
            & > svg {
                position: relative;
                margin: 0;
                width: 36px;
                height: 36px;
            }

            & > span {
                display: block;
                font-size: 13px;
                font-style: normal;
                font-weight: 350;
                line-height: 20px;
                color: var(--ds-main-gray);
                margin-left: 18px;
            }
        }
    }

    .safetyWrapper {
        flex-direction: column;
    }
    .safetyOuterWrapper {
        padding: 8px 18px;
    }

    .productsContainer {
        padding: 0;

        & > *:not(.safetyOuterWrapper) {
            padding: 0 18px;
        }
        & > *:first-child {
            padding: 0 0 0 18px;
        }
    }

    .goToProductWrapper {
        width: calc(100vw - 36px);
        height: 320px;

        & > p {
            width: 276px;
            font-size: 15px;
        }

        & > button {
            span {
                font-size: 14px;
            }
        }
    }

    .modalWrapper {
        width: calc(100% - 60px);

        & > div {
            padding: 24px;
        }
    }
}
