.titleWrapper {
    gap: 16px;

    img {
        width: 50px;
        height: 50px;
    }
}

.descriptionWrapper {
    font-family:
        Favorit Pro,
        sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
}

.infoWrapper {
    gap: 12px;

    & > div {
        gap: 6px;

        span {
            font-family:
                Favorit Pro,
                sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            text-align: center;
            color: var(--ds-main-dark-gray);
        }
    }
}
