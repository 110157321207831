.marketPointPanel {
    min-width: 197px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    background-color: var(--ds-bg-extra-light-gray);
    color: var(--ds-dtsip-text-black-100);
    padding: 3px 0 3px 8px;
    height: 36px;
    border-radius: 4px;
    border: 1px solid var(--ds-border-gray);
    cursor: pointer;

    & > span:first-child {
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: var(--ds-dtsip-text-black-100);
    }

    & > span:last-child {
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        padding-right: 10px;
    }
}

.pricePointPanel {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    background-color: var(--ds-bg-extra-light-gray);
    color: var(--ds-dtsip-text-black-100);
    padding: 3px 0 3px 8px;
    height: 36px;
    border-radius: 4px;
    border: 1px solid var(--ds-border-gray);
    cursor: pointer;

    & > span:first-child {
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: var(--ds-dtsip-text-black-100);
    }

    & > span:last-child {
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        padding-right: 10px;
    }
}
