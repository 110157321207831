.examplesWrapper {
    padding: 100px 62px;

    &__title {
        font-size: 40px;
        font-weight: 700;
        line-height: 100%;
        text-transform: uppercase;
        margin-bottom: 44px;
    }

    &__description {
        font-size: 18px;
        font-weight: 300;
        line-height: 24px;
        max-width: 851px;
        margin-bottom: 44px;
    }

    &__chartWrapper {
        display: flex;
        align-items: stretch;

        & > :first-child {
            margin-right: 56px;
        }

        &__chartSide {
            min-width: 851px;
            flex: 1;

            &__tabs {
                margin-bottom: 24px;
            }

            &__legend {
                margin-top: 14px;
                margin-bottom: 2px;
            }
        }

        &__codeSide {
            display: flex;
            flex-direction: column;

            &__codeWrapper {
                padding: 8px 8px 8px 0;
                margin-top: 4px;

                border: 1px solid #d8e1eb;
                background: #f5f7fa;
                border-radius: 6px;
            }

            &__code {
                flex: 1;

                padding: 4px 12px 12px 18px;
                gap: 10px;
                background: #f5f7fa;
                max-height: 100%;

                overflow: auto;

                &::-webkit-scrollbar {
                    width: 4px; /* Ширина скроллбара */
                }
                &::-webkit-scrollbar-track {
                    background: #ffffff; /* Цвет фона трека скроллбара */
                    border-radius: 4px;
                }
                &::-webkit-scrollbar-thumb {
                    border-radius: 4px;
                    background: #a5b1c0; /* Цвет бегунка скроллбара */
                }
            }
        }
    }
}

.subTitle {
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    margin-bottom: 30px;
}

.tabs {
    display: flex;
    align-items: center;

    & > :not(:last-child) {
        margin-right: 24px;
    }
    &__button {
        background: transparent;
        border: none;
        font-size: 16px;
        font-weight: 350;
        line-height: 18px;
        color: #88919a;
        border-bottom: 1px dashed #88919a;
        white-space: nowrap;
    }
}

.activeTab {
    font-size: 16px;
    font-weight: 350;
    line-height: 18px;

    color: #18191b;
    border: none;
}

.legend {
    display: flex;
    align-items: center;

    & > :not(:last-child) {
        margin-right: 36px;
    }

    &__item {
        display: flex;
        align-items: center;
        gap: 12px;

        &::before {
            content: '';
            width: 18px;
            height: 2px;
            background: #88919a;
        }
    }
}

.greenLegendItem {
    &::before {
        background: #3a9e4a;
    }
}

.redLegendItem {
    &::before {
        background: #e03b3b;
    }
}

@media screen and (max-width: 1929px) {
    .examplesWrapper {
        &__chartWrapper {
            &__chartSide {
                min-width: 851px;
            }
        }
    }
    .chart {
        height: 428px;
    }
}

@media screen and (max-width: 1359px) {
    .examplesWrapper {
        padding: 100px 24px 106px 26px;

        &__title {
            font-size: 32px;
            line-height: 32px;
        }

        &__chartWrapper {
            & > :first-child {
                margin-right: 40px;
            }

            &__chartSide {
                flex: 1;
                width: 100%;
                min-width: 600px;

                & > svg {
                    width: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: 1023px) {
    .legend {
        &__item {
            gap: 12px;
        }
    }
    .examplesWrapper {
        padding: 80px 24px 106px 26px;

        &__title {
            font-size: 32px;
            line-height: 32px;
        }

        &__chartWrapper {
            flex-direction: column;

            &__codeSide__code {
                max-height: 282px;
            }

            & > :first-child {
                margin-right: 0;
                margin-bottom: 31px;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .legend {
        flex-wrap: wrap;
        gap: 8px 0;

        & > :not(:last-child) {
            margin-bottom: 0;
            margin-right: 16px;
        }

        &__item {
            font-size: 14px;
            line-height: 18px;
            gap: 8px;

            &::before {
                width: 12px;
            }
        }
    }
    .examplesWrapper {
        padding: 60px 0 80px 24px;

        &__title {
            font-size: 20px;
            line-height: 20px;
            margin-bottom: 30px;
        }

        &__description {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 28px;
        }

        &__chartWrapper {
            flex-direction: column;

            & > :first-child {
                margin-right: 0;
                margin-bottom: 32px;
            }

            &__chartSide {
                flex: 1;
                width: 100%;
                min-width: unset;

                &__tabs {
                    margin-bottom: 16px;
                }

                & > svg {
                    width: 100%;
                }

                &__legend {
                    margin-top: 7px;
                }
            }

            &__codeSide {
                &__codeWrapper {
                    box-sizing: content-box;
                    margin-top: -4px;
                    margin-right: 12px;
                }
                &__code {
                    max-height: 282px;
                }
            }
        }
    }
    .subTitle {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;
        margin-bottom: 20px;
    }
    .tabs {
        &__button {
            font-size: 14px;
        }

        & > :first-child {
            margin-right: 16px;
        }
    }
}

@media screen and (max-width: 375px) {
    .examplesWrapper {
        padding: 60px 18px;
    }
}
