.mapsContainer {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 60px;
    padding: 0 36px 0 36px;
}

.mapHeaderTitle {
    display: flex;
    align-items: center;
    gap: 16px;

    .disabled {
        color: var(--ds-main-gray);
    }

    .switchRoot {
        width: 32px;
        height: 18px;
    }

    .switch {
        background-color: var(--ds-bg-blue-grey);
    }

    & > h2 {
        color: var(--ds-dtsip-text-black-100);
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        cursor: pointer;
    }
}

.chartToggleCheckboxContainer {
    display: flex;
    align-items: center;

    & > .checkbox {
        outline: none;
        height: 24px;
        width: 24px;
        margin-right: 8px;
        box-shadow: none;
        border-color: initial;
    }

    & > label {
        font-weight: 350;
        font-size: 14px;
        line-height: 16px;
    }
}

@media (max-width: 1024px) {
    .mapsContainer{
    }
}

@media (max-width: 767px) {
    .mapsContainer{
        padding: 0;
    }
}

@media (max-width: 360px) {
    .mapsContainer{
    }
}

