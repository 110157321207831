.serviceBlockWrapper {
    gap: 24px;

    & > h4 {
        font-family:
            Favorit Pro,
            sans-serif;
        font-size: 22px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: -0.01em;
        text-align: left;
    }

    & > div {
        gap: 24px;
        flex-wrap: wrap;
    }
}

@media (max-width: 1359px) {
    .serviceBlockWrapper {
        & > div {
            gap: 18px;
        }
    }
}

@media (max-width: 767px) {
    .serviceBlockWrapper {
        gap: 16px;

        & > h4 {
            font-size: 18px;
        }

        & > div {
            gap: 16px;
        }
    }
}
