.footerWrapper {
    width: 100%;
    height: 488px;
    padding: 136px 454px 0 62px;
    background:
        url('../../../../shared/images/svg/footerBg/footerCubesXl.svg') center right / auto 100% no-repeat,
        linear-gradient(277.23deg, rgba(216, 225, 235, 0.8) 0%, rgba(216, 225, 235, 0.2) 100%);

    & > h2 {
        font-size: 56px;
        font-weight: 500;
        line-height: 60px;
        letter-spacing: -0.01em;
        text-align: left;
        text-transform: uppercase;
        margin-bottom: 41px;
    }

    & > button {
        height: 56px;
        background: var(--ds-main-red);
        border: none;
        border-radius: 6px;
        padding: 16px 30px 16px 20px;
        gap: 8px;
        cursor: pointer;

        span {
            font-size: 16px;
            font-weight: 350;
            line-height: 20px;
            text-align: left;
            color: var(--ds-main-white);
        }
    }
}

@media (max-width: 1359px) {
    .footerWrapper {
        height: 487px;
        padding: 136px 212px 0 48px;
        background:
            url('../../../../shared/images/svg/footerBg/footerCubesLg.svg') center right / auto 100% no-repeat,
            linear-gradient(277.23deg, rgba(216, 225, 235, 0.8) 0%, rgba(216, 225, 235, 0.2) 100%);

        & > h2 {
            font-size: 46px;
            margin-bottom: 40px;
        }
    }
}

@media (max-width: 1023px) {
    .footerWrapper {
        height: 319px;
        padding: 76px 135px 0 24px;
        background:
            url('../../../../shared/images/svg/footerBg/footerCubesMd.svg') center right / auto 100% no-repeat,
            linear-gradient(277.23deg, rgba(216, 225, 235, 0.8) 0%, rgba(216, 225, 235, 0.2) 100%);

        & > h2 {
            font-size: 36px;
            line-height: 40px;
            margin-bottom: 31px;
        }
    }
}

@media (max-width: 767px) {
    .footerWrapper {
        height: 230px;
        padding: 60px 46px 0 18px;
        background:
            url('../../../../shared/images/svg/footerBg/footerCubesSm.svg') center right / auto 100% no-repeat,
            linear-gradient(277.23deg, rgba(216, 225, 235, 0.8) 0%, rgba(216, 225, 235, 0.2) 100%);

        & > h2 {
            font-size: 18px;
            line-height: 22px;
            margin-bottom: 18px;
        }

        & > button {
            height: 48px;
            padding: 12px 20px 12px 12px;
        }
    }
}
