.paymentModal {
    padding-top: 48px;
    background: var(--ds-main-white);
    width: 480px;
}

.titleWrapper {
    gap: 8px;
    padding: 0 48px;
    margin-bottom: 32px;

    h4 {
        font-size: 18px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
        color: var(--ds-main-black);
    }

    div {
        width: 384px;
        height: 46px;
    }
}

.tariffsWrapper {
    padding: 0 48px;
    margin-bottom: 32px;
    gap: 16px;
}

.dropdownWrapper {
    gap: 8px;

    span {
        font-size: 16px;
        font-weight: 350;
        line-height: 20px;
        text-align: left;
        color: var(--ds-main-black);
    }

    & > div {
        width: 100%;
    }
}

.totalSumWrapper {
    padding: 0 48px;
    margin-bottom: 32px;
    gap: 10px;

    .sumItem {
        p {
            font-size: 16px;
            font-weight: 350;
            line-height: 20px;
            text-align: left;
            color: var(--ds-main-gray);

            &:last-child {
                font-weight: 400;
                color: var(--ds-main-black);

                span {
                    margin-left: 4px;
                    font-size: 15px;
                    font-weight: 300;
                    line-height: 20px;
                    text-align: left;
                    color: var(--ds-main-gray);
                }
            }
        }

        &.totalItem {
            p {
                color: var(--ds-main-black);
            }
        }
    }
}

.paymentMethodWrapper {
    background: var(--ds-bg-pale-blue);
    padding: 24px 48px 48px;
    gap: 12px;

    h6 {
        font-size: 18px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
        color: var(--ds-main-black);
    }

    button {
        height: 56px;
        padding: 16px 24px;
        border: none;
        border-radius: 6px;
        background: var(--ds-main-red);

        span {
            font-size: 16px;
            font-weight: 350;
            line-height: 24px;
            text-align: center;
            color: var(--ds-main-white);
        }
    }
}
