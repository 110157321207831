.serviceItemWrapper {
    border: 1px solid var(--ds-border-gray);
    border-radius: 6px;
    width: calc(50% - 12px);
    height: 283px;

    & > div {
        width: 50%;
        padding: 36px;
    }

    & > img {
        width: 50%;
    }

    .titleWrapper {
        & > h6 {
            margin-bottom: 14px;
            font-family:
                Favorit Pro,
                sans-serif;
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            text-align: left;

            &.notActive {
                color: var(--ds-main-gray);
            }
        }

        & > p {
            font-size: 14px;
            font-weight: 300;
            line-height: 18px;
            text-align: left;

            &.notActive {
                color: var(--ds-main-gray);
            }
        }
    }

    button {
        width: fit-content;
        height: 42px;
        padding: 12px 24px;
        background-color: var(--ds-main-red);
        border: none;

        span {
            font-family:
                Favorit Pro,
                sans-serif;
            font-size: 16px;
            font-weight: 350;
            line-height: 18px;
            text-align: left;
            color: var(--ds-main-white);
            white-space: nowrap;
        }

        &.notActive {
            display: flex;
            gap: 6px;
            padding: 9px 24px 9px 12px;
            background: var(--ds-main-white);
            border: 1px solid var(--ds-main-gray);
            cursor: default;

            span {
                color: var(--ds-main-black);
            }
        }
    }
}
.item {
    &_content,
    &_image {
        width: 50%;
    }

    &_content {
        min-width: 245px;
    }

    &_image {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }
}

.popupWrapper {
    width: 720px;
    padding: 40px 48px 36px;

    & > h2 {
        font-size: 24px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;
        text-transform: uppercase;

        margin-bottom: 25px;
    }

    & > p {
        font-size: 16px;
        font-weight: 350;
        line-height: 24px;
        text-align: left;

        margin-bottom: 18px;
    }

    & > ul {
        margin-bottom: 42px;

        li {
            list-style: none;
            position: relative;
            padding-left: 18px;

            font-size: 16px;
            font-weight: 350;
            line-height: 24px;
            text-align: left;
            color: var(--ds-main-black);

            &:not(:last-child) {
                margin-bottom: 12px;
            }

            &:before {
                content: '';
                position: absolute;
                top: 10px;
                left: 0;
                display: inline-block;
                width: 6px;
                height: 6px;
                background-color: var(--ds-main-red);
            }
        }
    }

    & > button {
        height: 42px;
        padding: 12px 24px;
        border: none;
        border-radius: 6px;
        background: var(--ds-main-black);

        span {
            font-size: 16px;
            font-weight: 350;
            line-height: 18px;
            text-align: left;
            color: var(--ds-main-white);
        }
    }
}

@media (max-width: 1359px) {
    .serviceItemWrapper {
        width: calc(50% - 9px);

        & > div {
            padding: 30px 20px 26px 24px;
        }

        button {
            &.notActive {
                width: 200px;
            }
        }
    }

    .item {
        &_content {
            padding: 30px 20px 26px 24px;
        }
    }
}

@media (max-width: 1023px) {
    .serviceItemWrapper {
        height: 427px;
        flex-direction: column-reverse;
        justify-content: start;

        & > div {
            width: 100%;
            height: 100%;
            padding: 27px 14px 26px;
        }

        & > img {
            width: 100%;
            height: 217px;
            border-bottom: 1px solid var(--ds-border-gray);
        }
    }
}

@media (max-width: 767px) {
    .serviceItemWrapper {
        width: 100%;
        height: 440px;

        & > div {
            width: 100%;
            height: 100%;
            padding: 19px 24px 24px;
        }

        & .titleWrapper > h6 {
            margin-bottom: 5px;
        }
    }

    .popupWrapper {
        width: 336px;
        padding: 26px 24px 24px;

        & > h2 {
            font-size: 18px;
            font-weight: 600;
            line-height: 20px;

            margin-bottom: 17px;
        }

        & > p {
            font-size: 14px;
            font-weight: 350;
            line-height: 20px;

            margin-bottom: 15px;
        }

        & > ul {
            margin-bottom: 59px;

            li {
                font-size: 14px;
                line-height: 20px;

                &:not(:last-child) {
                    margin-bottom: 10px;
                }

                &:before {
                    content: '';
                    position: absolute;
                    top: 5px;
                    left: 0;
                    display: inline-block;
                    width: 6px;
                    height: 6px;
                    background-color: var(--ds-main-red);
                }
            }
        }

        & > button {
            height: 42px;
            padding: 12px 24px;
            border: none;
            border-radius: 6px;
            background: var(--ds-main-black);

            span {
                font-size: 16px;
                font-weight: 350;
                line-height: 18px;
                text-align: left;
                color: var(--ds-main-white);
            }
        }
    }
}
