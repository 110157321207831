.pinButton {
    padding: 6px 8px;
    background-color: var(--ds-main-white);
    border-radius: 6px;
    border: 1px solid var(--ds-border-gray);
    margin-right: 16px;

    .pinButtonCount {
        margin-left: 3px;
        width: 18px;
        height: 18px;
        border-radius: 4px;
        background-color: #ff5b01;
        color: var(--ds-main-white);
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
    }
}

.markerModal {
    width: 230px;
    padding: 16px;

    .menu {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
    }

    .marketPointPanel {
        width: 197px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: var(--ds-bg-extra-light-gray);
        color: var(--ds-dtsip-text-black-100);
        padding: 3px 0 3px 8px;
        height: 36px;
        border-radius: 4px;
        border: 1px solid var(--ds-border-gray);
        cursor: pointer;

        & > div:first-child {
            display: flex;
            align-items: center;
            gap: 8px;

            & > span:first-child {
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                color: var(--ds-dtsip-text-black-100);
            }

            & > span:last-child {
                font-size: 13px;
                font-style: normal;
                font-weight: 700;
                line-height: 20px;
                color: var(--ds-bg-green);
            }
        }

        & > div:last-child {
            padding: 10px 4px 4px 4px;
            border-left: 1px solid var(--ds-border-gray);
        }
    }

    .divider {
        width: 100%;
        margin: 8px 0;
        border-bottom: 1px solid var(--ds-border-gray);
    }

    .clearButton {
        width: 197px;
        background-color: var(--ds-main-white);
        padding: 6px 12px;
        height: 36px;
        border-radius: 6px;
        border: 1px solid var(--ds-border-gray);

        & span {
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            color: var(--ds-main-black);
        }
    }

    .saveButton {
        width: 197px;
        background-color: var(--ds-main-blue);
        padding: 6px 16px;
        height: 36px;
        border-radius: 6px;
        border: none;

        & span {
            padding: 0 5px;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            color: var(--ds-main-white);
        }
    }
}
