.controlsWrapper {
    width: 734px;
    gap: 30px;
    padding: 32px 36px 32px 36px;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--ds-border-gray);
    background-color: var(--ds-main-white);
    border-radius: 12px;
    caret-color: transparent;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;

    .selectorWrapper {
        gap: 0px;
        width: 268px;
        border-radius: 6px;
        border: 1px solid var(--ds-border-gray);
        display: flex;
        flex-direction: row;
        cursor: pointer;

        .labelSelector {
            padding: 8px 18px 8px 18px;
            border-radius: 6px;
            font-family:
                Favorit Pro,
                sans-serif;
            font-size: 15px;
            font-weight: 350;
            line-height: 20px;
            text-align: left;
        }

        .activeSelector {
            background-color: var(--ds-dtsip-text-black-100);
            border-color: (--ds-dtsip-text-black-100);
            color: var(--ds-main-white);
        }
    }

    .bottomSection {
        width: 100%;
        display: flex;

        .calendarWrapper {
            & > div {
                height: 36px;
            }

            .calendarInput {
                font-family: Inter, sans-serif;
                font-size: 14px;
                font-weight: 400;
                line-height: 16px;
                color: var(--ds-dtsip-text-black-100);
            }
        }

        .dropdownsBlockWrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: 12px;

            .dropdown,
            .smallDropdown {
                line-height: 16px;
                display: flex;

                & > div {
                    width: 100%;
                    height: 42px;

                    & > span {
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        display: inline-block;
                        padding-bottom: 4px;
                        height: auto;
                        vertical-align: middle;
                        width: 100%;
                        padding-top: 10px;
                        padding-bottom: 10px;
                        overflow: hidden;
                    }
                }
            }
        }

        .titleWrapper {
            gap: 8px;

            & > p {
                margin-bottom: 8px;
            }
        }

        .divider {
            width: 1px;
            background: var(--ds-border-gray);
            margin: 0 30px;
        }
    }
}

@media (max-width: 1599px) {
    .controlsWrapper {
        width: 324px;
        flex-shrink: 2;
        flex-direction: column;
        align-items: start;
        padding: 24px 28px 24px 28px;
        justify-content: start;
        gap: 8px;
        margin: 0;

        .bottomSection {
            flex-direction: column;
            gap: 12px;

            .divider {
                display: none;
            }

            .dropdownsBlockWrapper {
                flex-direction: column;
            }
        }
    }
}

@media (max-width: 1023px) {
    .controlsWrapper {
        width: 100%;
        max-width: 698px !important;
        flex-direction: column;
        align-items: initial;
        padding: 32px 36px 32px 36px;
        gap: 30px;

        .bottomSection {
            flex-direction: row;

            .dropdownsBlockWrapper {
                flex-direction: row;
            }

            .divider {
                display: block;
                width: 1px;
                background: var(--ds-border-gray);
                margin: 0 30px;
            }
        }

        & + div {
            width: 698px;
        }
    }
}

@media (max-width: 767px) {
    .controlsWrapper {
        // width: 324px;
        width: 100%;
        flex-direction: column;
        align-items: start;
        justify-content: start;
        margin: 0;
        padding: 16px;
        gap: 24px;
        font-size: 13px;

        & + div {
            width: 100%;
        }

        .bottomSection {
            flex-direction: column;
            gap: 24px;

            .calendarWrapper {
                height: 36px;
                .calendarInput {
                    font-size: 13px;
                }
            }
            .divider {
                display: none;
            }

            .dropdownsBlockWrapper {
                flex-direction: column;

                .smallDropdown,
                .dropdown {
                    & > div {
                        height: 36px;

                        & > span {
                            padding-top: 8px;
                        }
                    }
                }
            }
        }
    }
}
