.headerLogo {
    width: 220px;
    height: 36px;
}

.navigationItem {
    color: var(--ds-main-black);
    font-size: 16px;
    font-weight: 350;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: left;
    cursor: pointer;
}
