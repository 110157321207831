.horizontalSliderContainer {
    position: relative;
    height: 36px;
    width: 91%;
    border-radius: 6px;
    border: 1px solid var(--ds-border-gray);
    margin-left: 6px;

    .chartPreview {
        height: 36px;
        width: 100%;
    }

    .sliderOverlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 36px;
        width: 100%;
        z-index: 10;

        .sliderRoot {
            height: 36px;
            background-color: rgba(245, 247, 250, 0.1);

            .range {
                background-color: rgba(47, 156, 235, 0.2);
                border-left: 2px solid var(--ds-main-blue);
                border-right: 2px solid var(--ds-main-blue);
            }

            .handle {
                margin-top: -0.5715rem;
                margin-left: -0.5715rem;
            }
        }
    }
}

.verticalSliderRoot {
    margin-top: 10px;
    margin-right: 8px;
    height: 88%;
    width: 2px;
    background-color: var(--ds-border-gray);

    .range {
        background-color: var(--ds-main-blue);
    }

    .handle {
        margin-left: -0.5715rem;
        margin-bottom: -0.5715rem;
    }
}
