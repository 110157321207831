.paymentInfoWrapper {
    margin: 197px auto;
    padding: 48px 48px 0;
    border: 1px solid var(--ds-border-gray);
    border-radius: 6px;
    width: 571px;

    svg {
        width: 72px;
        height: 72px;
    }

    h2 {
        font-family: Inter, sans-serif;
        font-size: 24px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0;
        text-align: center;
        text-transform: uppercase;
        padding: 48px 0 24px;
    }

    p {
        font-family: Inter, sans-serif;
        font-size: 16px;
        font-weight: 300;
        line-height: 20px;
        letter-spacing: 0;
        text-align: left;
        color: var(--ds-main-black);
    }

    .buttonWrapper {
        width: 100%;

        button {
            height: 42px;
            margin: 48px 0;
            padding: 12px 24px;
            border: none;
            border-radius: 6px;
            background: var(--ds-main-black);

            span {
                font-family: Inter, sans-serif;
                font-size: 16px;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0;
                text-align: left;
                color: var(--ds-main-white);
            }
        }
    }
}
