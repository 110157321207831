.filtersContainer {
    width: 337px;
    border-radius: 16px;
    border: 1px solid var(--ds-border-gray);
    padding: 32px 36px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}

.filterModal {
    width: 560px;
    padding: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;

    & > h3 {
        align-self: start;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        color: var(--ds-main-black);
    }
}

.blockDivider {
    width: 100%;
    border-bottom: 1px solid var(--ds-border-gray);
}

.showButton {
    padding: 6px 16px;
    height: 36px;
    border-radius: 6px;
    border: none;
    flex-grow: 1;

    & span {
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: var(--ds-main-white);
    }
}

.clearButton {
    padding: 6px 12px;
    height: 36px;
    border-radius: 6px;
    border: 1px solid var(--ds-border-gray);

    & span {
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: var(--ds-main-black);
    }
}

@media (max-width: 1024px) {

}

@media (max-width: 767px) {
    .filtersContainer {
        width: 100%;
        border-radius: 12px;
        border: 1px solid var(--ds-bg-light-blue);
        padding: 24px 36px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        cursor: pointer;

        & > h2 {
            color: var(--ds-main-black);
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
        }

        & > button {
            background-color: var(--ds-main-white);
            padding: 6px 12px;
            height: 36px;
            border-radius: 6px;
            border: 1px solid var(--ds-border-gray);

            & span {
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                color: var(--ds-main-black);
            }
        }
    }

    .filterModal {
        width: 90vw;
        gap: 30px;
        padding: 32px 36px;
    }

    .showButton {
        flex-grow: 0;
    }
}

@media (max-width: 360px) {
    .filtersContainer {
        border-radius: 6px;
        padding: 8px 16px;

        & > {
            color: var(--ds-main-black);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
        }
    }

    .filterModal {
        width: 90vw;
        gap: 24px;
        padding: 32px 16px;
    }

    .showButton {
        flex-grow: 0;
    }
}





