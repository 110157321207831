.inputLabel {
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 16px;
}

.calendarInput {
    width: 10rem;
    height: 36px;
    border-radius: 6px;
    border: 1px solid var(--ds-border-gray);

    & > input {
        min-width: 4.5rem;
        padding: 6px 12px 6px 18px;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 16px;
        border: none;
    }

    & > button {
        border: none;
        background-color: var(--ds-main-white);
        color: var(--ds-main-blue);
    }
}

.blockTitle {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 12px;

    & > i {
        color: var(--ds-main-gray);
    }

    & > p {
        color: var(--ds-main-black);
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: -1%;
        text-transform: uppercase;
    }
}

.dropdownInput {
    height: 36px;
    border-radius: 6px;
    border: 1px solid var(--ds-border-gray);

    & > span {
        color: var(--ds-main-gray);
        padding: 8px 18px;
        font-size: 14px;
        font-style: normal;
        font-weight: 350;
        line-height: 20px;
    }

    & > div {
        color: var(--ds-main-gray);
        padding: 8px 18px;
        font-size: 14px;
        font-style: normal;
        font-weight: 350;
        line-height: 20px;
    }
}

.createListInput {
    flex-grow: 1;
    height: 36px;
    border-radius: 6px;
    border: 1px solid var(--ds-border-gray);

    & > input {
        padding: 6px 12px 6px 18px;
        font-size: 14px;
        font-style: normal;
        font-weight: 350;
        line-height: 20px;
        border: none;
    }

    & > button {
        border: none;
        background-color: var(--ds-main-white);
        color: var(--ds-main-light-gray);
    }
}

.createListButton {
    background-color: var(--ds-main-white);
    color: var(--ds-main-black);
    padding: 6px 16px 6px 16px;
    height: 36px;
    border-radius: 6px;
    border: 1px solid var(--ds-border-gray);

    & > span:first-child {
        padding: 0 5px;
        color: var(--ds-main-blue);
    }
}

.profitInput {
    height: 36px;
    border-radius: 6px;
    border: 1px solid var(--ds-border-gray);
    color: var(--ds-main-gray);
    padding: 8px 18px;
    font-size: 14px;
    font-style: normal;
    font-weight: 350;
    line-height: 20px;
}

.extendedSearchLabel {
    align-self: start;
    font-size: 14px;
    font-weight: 350;
    line-height: 18px;
    color: var(--ds-main-blue);
    text-decoration: underline;
    cursor: pointer;
}
