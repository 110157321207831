.tariffFilterWrapper {
    gap: 6px;
}

.tariffFilterItem {
    position: relative;
    height: 40px;
    padding: 13px 18px 11px;
    border: 1px solid var(--ds-border-gray);
    border-radius: 6px;
    cursor: pointer;

    font-family:
        Favorit Pro,
        sans-serif;
    font-size: 16px;
    font-weight: 350;
    line-height: 12px;
    letter-spacing: 0;
    text-align: left;
    //text-decoration-line: underline;
    //text-decoration-style: dashed;
    color: var(--ds-main-gray);

    &.active {
        background: var(--ds-main-black);
        border: none;
        color: var(--ds-main-white);
        text-decoration-line: none;
    }

    &:not(.active)::after {
        content: '';
        position: absolute;
        bottom: 9px;
        left: 18px;
        width: calc(100% - 36px);
        border-bottom: 1px dashed var(--ds-main-gray);
    }
}

.panelStyle {
    li.p-highlight {
        color: var(--ds-main-blue);
    }
}

.dropdownFilter {
    width: 190px;
}
