.dataUploadContainer {
    min-height: 100vh;
    padding: 48px 36px;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 40px;

    & > h1 {
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        color: var(--ds-main-black);
    }

    .dataUpload {
        width: 100%;
        border-radius: 12px;
        border: 1px solid var(--ds-border-gray);
        padding: 32px 36px;
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 30px;
    }

    .toggleButtons {
        border-radius: 12px;
        border: 1px solid var(--ds-border-gray);
        display: flex;
        align-items: center;

        .toggleActive {
            background-color: var(--ds-dtsip-text-black-100);;
            padding: 6px 16px;
            border-radius: 12px;
            border: none;
            flex-grow: 1;

            & span {
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                color: var(--ds-main-white);
            }
        }

        .toggleInactive {
            background-color: var(--ds-main-white);
            padding: 6px 16px;
            border-radius: 12px;
            border: none;
            flex-grow: 1;

            & span {
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                color: var(--ds-main-black);
            }
        }
    }

    .mainBlock {
        display: flex;
        align-items: start;
        flex-wrap: wrap;
        gap: 30px;

        & > div {
            display: flex;
            flex-direction: column;
            align-items: start;
            gap: 16px;

            & > p {
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 16px;
                color: var(--ds-main-black);
            }
        }

        .autoCompleteInput {
            width: 443px;
            height: 36px;
            border-radius: 6px;
            border: 1px solid var(--ds-border-gray);

            & > input {
                padding: 6px 12px 6px 18px;
                font-size: 14px;
                font-style: normal;
                font-weight: 300;
                line-height: 16px;
                border: none
            }

            & > button {
                border: none;
                background-color: var(--ds-main-white);
                color: var(--ds-main-light-gray);
            }
        }

        .calendarInput {
            height: 36px;
            border-radius: 6px;
            border: 1px solid var(--ds-border-gray);

            & > input {
                width: 8rem;
                padding: 6px 12px 6px 18px;
                font-size: 14px;
                font-style: normal;
                font-weight: 300;
                line-height: 16px;
                border: none
            }

            & > button {
                border: none;
                background-color: var(--ds-main-white);
                color: var(--ds-main-blue);
            }
        }

        .listDownloadButton {
            background-color: var(--ds-main-white);
            color: var(--ds-main-black);
            padding: 6px 20px 6px 16px;
            height: 36px;
            border-radius: 6px;
            border: 1px solid var(--ds-border-gray);

            & > span:first-child {
                padding: 0 5px;
                color: var(--ds-main-blue);
            }

            & > span:last-child {
                padding: 0 5px;
                font-size: 13px;
                font-style: normal;
                font-weight: 350;
                line-height: 20px;
                color: var(--ds-main-black);
            }
        }
    }

    .selectedItem {
        background-color: var(--ds-bg-extra-light-gray);
        color: var(--ds-dtsip-text-black-100);
        padding: 3px 0 3px 8px;
        height: 36px;
        border-radius: 4px;
        border: 1px solid var(--ds-border-gray);
        cursor: auto;

        & > span {
            padding: 0 8px 0 0;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            color: var(--ds-dtsip-text-black-100);
        }

        & > i {
            cursor: pointer;
            padding: 7px 10px;
            border-left: 1px solid var(--ds-border-gray);
        }
    }

    .saveButtons {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 16px;

        .saveButton {
            background-color: var(--ds-main-blue);
            padding: 6px 16px;
            height: 36px;
            border-radius: 6px;
            border: none;
            flex-grow: 1;

            & span {
                padding: 0 5px;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                color: var(--ds-main-white);
            }
        }

        .clearButton {
            background-color: var(--ds-main-white);
            padding: 6px 12px;
            height: 36px;
            border-radius: 6px;
            border: 1px solid var(--ds-border-gray);

            & span {
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                color: var(--ds-main-black);
            }
        }
    }

    .blockDivider {
        width: 100%;
        border-bottom: 1px solid var(--ds-border-gray);
    }
}


@media (max-width: 767px) {
    .dataUploadContainer {
        .mainBlock {
            .autoCompleteInput {
                width: 228px;
            }
        }
    }
}

@media (max-width: 360px) {
    .dataUploadContainer {
        .mainBlock {
            .autoCompleteInput {
                width: 150px;
            }
        }
    }
}


