.inputLabel {
    color: var(--ds-dtsip-text-black-100);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 16px;
}

.dropdownInput {
    width: 316px;
    height: 36px;
    border-radius: 6px;
    border: 1px solid var(--ds-border-gray);

    & > span {
        color: var(--ds-main-gray);
        padding: 8px 18px;
        font-size: 14px;
        font-style: normal;
        font-weight: 350;
        line-height: 20px;
    }
}

.dateRangeBlock {
    width: 316px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;

    & > i {
        font-size: 8px;
    }

    .calendarInput {
        flex-grow: 1;
        height: 36px;
        border-radius: 6px;
        border: 1px solid var(--ds-border-gray);

        & > input {
            min-width: 4rem;
            padding: 6px 12px 6px 14px;
            font-size: 14px;
            font-style: normal;
            font-weight: 350;
            line-height: 16px;
            border: none
        }

        & > button {
            border: none;
            background-color: var(--ds-main-white);
            color: var(--ds-main-blue);
        }
    }
}

.inputBoundary {
    width: 58px;
    height: 36px;
    border-radius: 6px;
    border: 1px solid var(--ds-border-gray);

    & > span {
        color: var(--ds-main-gray);
        padding: 8px 18px;
        font-size: 14px;
        font-style: normal;
        font-weight: 350;
        line-height: 20px;
    }
}
