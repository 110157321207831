.wrapper {
  height: calc(100% - 155px);
}

.title {
  font-family: Favorit Pro, sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  text-align: center;
}

.subtitle {
  width: 460px;
  margin: 16px 0 24px;

  font-family: Favorit Pro, sans-serif;
  font-size: 16px;
  font-weight: 350;
  line-height: 24px;
  letter-spacing: 0;
  text-align: center;
}
.addButton {
  width: 235px;
  height: 50px;
  margin-top: 12px;
  padding: 6px;
  border: 0;
  border-radius: 6px;
  gap: 8px;
  background-color: var(--ds-bg-dark-blue);
}
