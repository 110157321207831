.logoBlockWrapper {
    height: 500px;
    background:
        url('../../../../../shared/images/svg/landingHeader/cubesXl.svg') center left / auto 100% no-repeat,
        url('../../../../../shared/images/complianceTool/png/complianceToolHeaderLogo.png') center right / auto 100%
            no-repeat;
}

.contentWrapper {
    padding: 127px 62px 54px 62px;

    & > svg {
        width: 542px;
        margin-bottom: 18px;
    }

    & > p {
        width: 590px;
        font-weight: 350;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 28px;
    }

    & > div {
        gap: 12px;

        & > button {
            height: 56px;
            padding: 18px 30px 18px 20px;
            display: flex;
            gap: 8px;
            align-items: center;
            background-color: var(--ds-main-red);
            border: none;

            & > span {
                color: var(--ds-main-white);
                font-weight: 350;
                font-size: 16px;
                line-height: 20px;
                display: block;
            }
        }
    }
}

@media (max-width: 1359px) {
    .logoBlockWrapper {
        height: 400px;
        background:
            url('../../../../../shared/images/svg/landingHeader/cubesLg.svg') center left / auto 100% no-repeat,
            url('../../../../../shared/images/complianceTool/png/complianceToolHeaderLogo.png') center right / auto 100%
                no-repeat;
    }

    .contentWrapper {
        padding: 74px 48px 45px 48px;

        & > svg {
            width: 405px;
            margin-bottom: 10px;
        }

        & > p {
            width: 381px;
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 26px;
        }
    }
}

@media (max-width: 1023px) {
    .logoBlockWrapper {
        background:
            url('../../../../../shared/images/svg/landingHeader/cubesMd.svg') center left / auto 100% no-repeat,
            url('../../../../../shared/images/complianceTool/png/complianceToolHeaderLogo.png') center right / auto 100%
                no-repeat;
    }

    .contentWrapper {
        padding: 62px 24px 40px 24px;

        & > svg {
            width: 406px;
            margin-bottom: 11px;
        }
    }
}

@media (max-width: 767px) {
    .logoBlockWrapper {
        height: 588px;
        background:
            url('../../../../../shared/images/svg/landingHeader/cubesSm.svg') left bottom / 144px 288px no-repeat,
            url('../../../../../shared/images/complianceTool/png/complianceToolHeaderLogoSm.png') right bottom / 100%
                288px no-repeat;
    }

    .contentWrapper {
        padding: 23px 18px 24px 18px;
        height: 300px;
        background: var(--ds-main-white);

        & > svg {
            width: 271px;
            margin-bottom: 1px;
        }

        & > p {
            width: 100%;
            font-size: 15px;
            line-height: 20px;
            margin-bottom: 26px;
        }

        & > div {
            & > button {
                height: 48px;
                padding: 12px 20px 12px 12px;

                & > span {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 24px;
                }
            }
        }
    }
}
