.dropdownWrapper {
    width: 100%;
    position: relative;

    .titleWrapper {
        width: 100%;
        height: 42px;
        border: 1px solid var(--ds-border-gray);
        border-radius: 6px;
        padding: 10px 18px;
        display: flex;
        justify-content: space-between;
        font-family: Favorit Pro;
        font-size: 14px;
        font-weight: 300;
        line-height: 16px;
        cursor: pointer;
        text-align: left;
        color: var(--ds-main-black);

        .title {
            width: 100%;
            flex-grow: 10;
            display: flex;
            align-items: center;
            transform: translateY(1px);
            text-overflow: ellipsis;
            overflow: hidden;
            text-wrap: nowrap;
        }

        .arrowWrapper {
            width: auto;
            flex-grow: 0;
            flex-shrink: 1;
            flex-direction: row;
            display: flex;
            margin-left: 8px;
            justify-content: flex-end;
            align-items: center;
        }
    }

    .panelWrapper {
        position: absolute;
        width: 100%;
        z-index: 10000000000000000;
        background: white;
        padding: 8px 0;
        border: 1px solid var(--ds-border-gray);
        border-radius: 6px;
        box-shadow: 8px 8px 0px 0px #40658026;
        top: 52px;
        opacity: 0;
        transition: opacity 0.3s;

        &.down {
            top: 52px;
            transition: 0.3s;
        }

        &.visible {
            opacity: 1;
        }

        &.up {
            top: -10px;
            transform: translateY(-100%);
        }

        &.fixedSize {
            height: 196px;
        }

        &.relativeSize {
            height: auto;
        }

        .listWrapper {
            display: flex;
            flex-direction: column;
            height: 100%;
            overflow-y: scroll;
            overflow-x: hidden;

            ::-webkit-scrollbar {
                width: 4px;
                border-radius: 50px;
                background-color: #d8e1eb;
            }

            ::-webkit-scrollbar-track {
                background: #a5b1c0;
                width: 4px;
                border-radius: 50px;
            }

            .listItem {
                padding: 8px 18px;
                font-family: Inter;
                font-size: 14px;
                font-weight: 350;
                cursor: pointer;
                line-height: 20px;
                text-align: left;
                color: var(--ds-main-black);

                &.active {
                    background: #b7cee533;
                }
            }
        }
    }
}
